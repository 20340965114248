.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
  }
  
  .customButton {
    background-color: black;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px; /* Slightly rounded corners */
    cursor: pointer;
    font-family: 'Arial', sans-serif; /* You can choose a different font here */
    transition: background-color 0.3s;
    width: 200px; /* Fixed width for both buttons */
    text-align: center; /* Ensure text is centered */
  }
  
  .customButton:hover {
    background-color: #333; /* Slightly lighter black on hover */
  }
  